.home-container {
	/* background-color: #1c1c1c; */
	background-color: #010101;
	color: #e4e6eb;
	display: flex;
	justify-content: center;
	height: 400px;
	padding-top: 20px;
}

.introduction-container {
	max-height: 90%;
	width: 35%;
	margin-right: 2%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
}

.greeting {
	text-transform: uppercase;
	font-family: 'Play', sans-serif;
	margin-bottom: 2%;
}

.my-name {
	font-size: 45px;
	font-family: 'Black Han Sans', sans-serif;
}

.my-message {
	/* color: green; */
	color: #006080;
	font-size: 45px;
	font-family: 'Black Han Sans', sans-serif;
}

.location {
	font-size: 40px;
	font-family: 'Black Han Sans', sans-serif;
	margin-top: 2%;
}

.profile-picture-container {
	max-height: 90%;
	width: 25%;
	margin-left: 2%;
}

.profile-picture {
	max-width: 100%;
	max-height: 100%;
	border-radius: 2%;
}

@media (max-width: 420px) {
	.home-container {
		/* background-color: #1c1c1c; */
		background-color: #010101;
		color: #e4e6eb;
		display: flex;
		justify-content: center;
		height: 150px;
		padding-top: 0px;
	}

	.introduction-container {
		max-height: 80%;
		width: 75%;
		/* margin-right: 2%; */
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.greeting {
		text-transform: uppercase;
		font-family: 'Play', sans-serif;
		font-size: 10px;
		margin-bottom: 2%;
	}

	.my-name {
		font-size: 20px;
		font-family: 'Black Han Sans', sans-serif;
	}

	.my-message {
		/* color: green; */
		color: #006080;
		font-size: 20px;
		font-family: 'Black Han Sans', sans-serif;
	}

	.location {
		font-size: 16px;
		font-family: 'Black Han Sans', sans-serif;
		margin-top: 2%;
	}

	.profile-picture-container {
		display: none;
	}
}
