.header-container {
	background-color: #18191a;
	/* background-color: #010101; */
	padding: 20px;
	text-align: center;
	z-index: 12;
	position: fixed;
	width: 100%;
	display: flex;
	align-content: space-around;
}

.logo {
	margin-right: 20px;
	font-size: 25px;
	color: #e4e6eb;
}

.navbar-container {
	flex-basis: 90%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.nav > a {
	text-decoration: none;
	color: #e4e6eb;
}

.nav > .tab-active {
	/* color: green; */
	color: #007399;
}

.nav > .tab-inactive {
	color: #e4e6eb;
}

.socials {
	margin-left: 10px;
	justify-self: flex-end;
	display: flex;
	gap: 10px;
}

.social > a {
	color: #e4e6eb;
	font-size: 20px;
}

/* @media (max-width: 420px) {
	.tab-hide {
		display: none;
	}
} */

@media (min-width: 420px) {
	.mobile-navbar {
		display: none;
	}
}

@media (max-width: 420px) {
	.header-container {
		background-color: #18191a;
		/* background-color: #010101; */
		padding: 0px;
		text-align: center;
		z-index: 12;
		position: fixed;
		width: 100%;
		display: flex;
		align-content: space-around;
	}
	.logo,
	.navbar-container,
	.socials {
		display: none;
	}
	.mobile-navbar {
		height: 30px;
		/* color: white; */
	}
	.mobile-logo {
		font-size: 25px;
		margin-top: 0px;
	}
	li > a {
		text-decoration: none;
		color: #e4e6eb;
	}
}
