.App {
	height: 100%;
	display: flex;
	flex-direction: column;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Cabin', sans-serif;
}
