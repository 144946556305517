.project-card-container-hidden {
  height: 270px;
  width: 29%;
  max-width: 345px;
  display: flex;
  margin: 1.6%;
  visibility: hidden;
}

.project-card-container {
  height: 270px;
  width: 29%;
  max-width: 345px;
  display: flex;
  margin: 1.6%;
  flex-direction: column;
  border: 5px solid #006080;
  border-radius: 8%;
}

.project-card-image {
  max-height: 80%;
  max-width: 100%;
}

.project-image {
  max-height: 100%;
  max-width: 100%;
  border-radius: 8% 8% 0 0;
}

.project-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.project-title {
  font-size: 18px;
  font-family: "Black Han Sans", sans-serif;
}

.project-links {
  display: flex;
  flex-direction: column;
}

.project-link {
  margin: 2px 5px;
}

.project-link > a {
  color: #e4e6eb;
  font-size: 20px;
}

.github-icon {
  margin-left: 8px;
}

@media (max-width: 420px) {
  .project-card-container-hidden {
    height: 225px;
    width: 70%;
    display: flex;
    margin: 1.6%;
    margin-left: 0px;
    display: none;
  }

  .project-card-container {
    height: 225px;
    width: 85%;
    display: flex;
    margin: 1.6%;
    margin-left: 0px;
    flex-direction: column;
    border: 5px solid #006080;
    border-radius: 8%;
    margin-bottom: 10px;
  }

  .project-card-image {
    max-height: 100%;
    max-width: 100%;
  }

  .project-image {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8% 8% 0 0;
  }

  .project-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }

  .project-title {
    font-size: 18px;
    font-family: "Black Han Sans", sans-serif;
  }

  .project-links {
    display: flex;
    flex-direction: column;
  }

  .project-link {
    margin: 2px 5px;
  }

  .project-link > a {
    color: #e4e6eb;
    font-size: 20px;
  }

  .github-icon {
    margin-left: 8px;
  }
}
