.main-container {
	margin-top: 69px;
	background-color: antiquewhite;
	padding: 0;
	flex-grow: 1;
}

@media (max-width: 420px) {
	.main-container {
		margin-top: 50px;
	}
}
